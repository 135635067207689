<template>
  <section>
    <section class="banner">
      <div class="centre">
        <div class="banner-carousel">
          <!-- <div class="banner-carousel-item">
            <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/19/0e36518a92ff49c0b4fc2c3140f81c4e.png" alt="">
          </div> -->
          <div class="banner-carousel-item">
            <div class="banner-box swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/19/0e36518a92ff49c0b4fc2c3140f81c4e.png" alt="">
                </div>
                <div class="swiper-slide">
                  <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/03/25/b08fde8bfce047bea19e34e7ede23def/心脏.png" alt="">
                </div>
                <!-- <div class="swiper-slide">
                  <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/02/22/8a43007d9b084e39ad123943f5871eb2/组红色.png" alt="">
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="card-list">
          <ul>
            <li class="card-item" @click="windowOpen('https://mm.sciconf.cn/en/minisite/program-view/21452')">
              <div class="card-box">
                <h4 class="card-title">Program at a Glance</h4>
                <img class="card-icon" src="@/assets/img/home/Program.png" alt="">
              </div>
            </li>
            <li class="card-item" @click="windowOpen('https://ascvts2024.sciconf.cn/en/web/program/21452')">
              <div class="card-box">
                <h4 class="card-title">Scientific Program</h4>
                <img class="card-icon" src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/24/b0faf0448d0f423b966f2297387e30ca.png" alt="">
              </div>
            </li>
            <li class="card-item" @click="windowOpen('https://ascvts2024.sciconf.cn/en/web/speaker/21452')">
              <div class="card-box">
                <h4 class="card-title">Speakers</h4>
                <img class="card-icon" src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/24/06014c941b8d4e05a650e37da8022f41.png" alt="">
              </div>
            </li>
            <li class="card-item" @click="windowOpen('https://www.drvoice.cn/v2/course/3388')">
              <div class="card-box">
                <h4 class="card-title">Live Stream</h4>
                <img class="card-icon" src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/23/e8fc6efc2ca14014945fbe804d51cda5.png" alt="">
              </div>
            </li>
            <li class="card-item" @click="windowOpen('https://www.pailixiang.com/m/album/main_ig67669899.html')">
              <div class="card-box">
                <h4 class="card-title">Photos</h4>
                <img class="card-icon" src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/24/f4f90edb37864c67afe04428e535ab8e.png" alt="">
              </div>
            </li>
            <li class="card-item" @click="$router.push({name:'abstractInfo'})">
              <div class="card-box">
                <h4 class="card-title">Abstract <br> Submission</h4>
                <img class="card-icon" src="@/assets/img/home/Leaflet.png" alt="">
              </div>
            </li>
            
            <!-- <li class="card-item" @click="toBeUpdate">
              <div class="card-box">
                <h4 class="card-title">Shuttle Bus</h4>
                <img class="card-icon" src="@/assets/img/home/Bus.png" alt="">
              </div>
            </li>
            <li class="card-item" @click="toBeUpdate">
              <div class="card-box">
                <h4 class="card-title">Floor Plan</h4>
                <img class="card-icon" src="@/assets/img/home/Floor.png" alt="">
              </div>
            </li> -->
          </ul>
        </div>
      </div>
    </section>
    <section class="container-box">
      <div class="container">
        <div class="container-first">
          <div class="icon-box" >
            <a href="http://www.ascvts2024wuhan.org.cn/sign">
              <div class="icon"><a-icon type="carry-out" theme="filled" /></div>
              <div class="box-text">
                <h4 class="title">April 15, 2024</h4>
                <p class="description">Early Bird Registration</p>
              </div>
            </a>
          </div>
          <div class="icon-box" >
            <a href="http://www.ascvts2024wuhan.org.cn/sign">
              <div class="icon"><a-icon type="contacts" theme="filled" /></div>
              <div class="box-text">
                <h4 class="title">May 7, 2024</h4>
                <p class="description">Pre Registration</p>
              </div>
            </a>
          </div>
          <div class="icon-box" >
            <a >
              <div class="icon"><a-icon type="container" theme="filled" /></div>
              <div class="box-text">
                <h4 class="title">May 23, 2024</h4>
                <p class="description">On Site Registration</p>
              </div>
            </a>
          </div>
        </div>
        <div class="container-top" v-if="speakerList.length > 0">
          <div class="main-title" style="display: flex;justify-content: space-between;align-items: baseline">
            GUEST FACULTY<!--<span>(Sort By Name)</span>-->
            <span class="more" @click="$router.push({name: 'invitedGuests'})">READ MORE <span class="iconfont icon-arrowright"></span></span>
          </div>
          <div class="main-box">
            <div class="speakers-slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in speakerList" :key="index">
                  <div class="speakers-card" v-if="item.realname">
                    <img class="img" :src="item.avatar"/>
                    <h3>{{item.realname}}</h3>
                    <p>{{item.site}}</p>
                    <div class="btns">
                      <div class="btn" v-for="(role,i) in item.speakerRoleList" :key="i">
                        {{ role.titleEn }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <div class="container-mid">
          <div class="left">
            <div class="main-title">
              WELCOME MESSAGE
            </div>
            <div class="main-box">
              <div class="img">
                <img src="@/assets/img/home/yaoqinghan.jpg" alt="">
              </div>
              <p>
                Dear colleagues and friends, <br>
                It is my great pleasure and honor to invite you to the next annual meeting of ASCVTS. ASCVTS 2024 is scheduled to be held from 23rd of May to 26th of May in Wuhan, China. I am looking forward to holding the 2024 annual meeting as an in-person event.<br>
                What we went through during past decades showed that communication make excellence. In order to enhance the cooperation between the cardiothoracic surgeons world-wide and make primary innovations in different field. The theme of ASCVTS 2024, “Cooperation, Innovation, Excellence”, reflects our resolution that the pursuit of high standards of patient care and academic excellence should be continued with stronger solidarity.
              </p>
              <div class="main-bottom">
                <span class="more cursor-pointer" @click="$router.push({name:'message'})">READ MORE <span class="iconfont icon-arrowright"></span></span>
              </div>
            </div>
          </div>
          <div class="centre">
            <div class="main-title">
              NEWSLETTER
            </div>
            <div class="main-box">
              <div class="img">
                <img src="@/assets/img/home/baodao.jpg" alt="">
              </div>
              <!-- <h4>38<sup>th</sup> Annual Scientific Meeting of KTCVS </h4> -->
              <h4></h4>
              <!-- <div class="main-bottom">
                <div class="num">1</div>
                <div class="num">2</div>
                <div class="num">3</div>
                <div class="num">4</div>
                <div style="flex:1">
                  <span class="more cursor-pointer" @click="toBeUpdate">READ MORE <span class="iconfont icon-arrowright"></span></span>
                </div>
              </div> -->
            </div>
          </div>
          <div class="right">
            <div class="bo1">DOWNLOAD CENTER <span class="iconfont icon-download"></span></div>
            <div class="bo2" @click="downloadFile('https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/ASCVTS%202024/ASCVTS%202024_Abstract%20PPT%20Template%20.pptx')"><img src="@/assets/img/home/sanjiao.png" alt=""> Abstract PPT Template</div>
            <div class="bo3" @click="toBeUpdate()"><img src="@/assets/img/home/sanjiao.png" alt="">Program Book</div> 
<!--            <div class="bo3" @click="toBeUpdate"><img src="@/assets/img/home/sanjiao.png" alt="">Poster</div>-->
            <div class="bo4" @click="toBeUpdate"><img src="@/assets/img/home/sanjiao.png" alt="">Announcement</div>
            <div class="bo5" @click="toBeUpdate"><img src="@/assets/img/home/sanjiao.png" alt="">Promotional Slide</div>
          </div>
        </div>

        <div class="container-bottom" v-if="sponsors.length > 0">
          <div class="main-title">
            SPONSORS
          </div>
            <div class="sroom-slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(sponsor, index) in sponsors" :key="index">
                  <div class="sroom-box">
                    <img :src="sponsor.customImage"/>
                  </div>
                </div>
              </div>
              <div class="sroom-swiper-pagination"></div>
            </div>
        </div>

        <div class="container-last">
          <div class="left">
            <!-- <div class="bgbox">
              <div class="main-title">
                WUHAN, CHINA
              </div>
              <div class="img">
                <img src="@/assets/img/home/wuhan.jpg" alt="">
                <img class="player" src="@/assets/img/home/video.png" alt="" @click="palyer()">
              </div>
            </div> -->
          </div>
          <div class="right">
            <div class="bgbox">
              <div class="main-title">
                WEATHER IN WUHAN
              </div>
              <div class="img">
                <img class="bg" src="@/assets/img/home/tianqi.jpg" alt="">
                <div class="box">
                    <div class="box-lt">{{temp}}° </div>
                    <div class="box-mid">{{weather}}</div>
                    <div class="box-rt"><img :src="weatherIcon" alt=""></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import VideoPopup from '@/components/VideoPopup'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import {getExpertsByGroupId} from "../../api/agenda";
import {getSponsorList} from "@/api/content";
import {getWeatherInfo} from "@/api/weather";

export default {
  name: "home",
  created(){
    this.initExpertsByGroupId()
    this.initSponsors()
    this.getWeatherInfo()
  },
  data() {
    return {
      speakerList: [],
      sponsors: [],
      temp: 15,
      weather: 'Clear',
      weatherIcon: require('@/assets/img/weather/01d@2x.png'),
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.initBannerSwiper()
      // 
      setTimeout(()=>{
        this.$info({
          title: 'Photos have been updated！',
          okText:'Go',
          closable: true,
          centered: true,
          onOk() {
            window.open('https://www.pailixiang.com/m/album/main_ig67669899.html')
          },
          maskClosable: true,
        });
      },1000)

    })
  },
  methods:{
    initSwiper() {
      const tEl = document.querySelector(".speakers-slider")
      if (!tEl) {
        setTimeout(() => {
          this.initSwiper()
        }, 100);
        return;
      }
      new Swiper('.speakers-slider', {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        loopFillGroupWithBlank:true,
        watchOverflow: true,
        // 断点
        breakpoints:{
          550:{
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween:'60',
          },
          860:{
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween:'40',
          },
          1120:{
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween:'40',
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          dynamicBullets: true,
        },
      });
    },
    initBannerSwiper() {
      const tEl = document.querySelector(".banner-box")
      if (!tEl) {
        setTimeout(() => {
          this.initBannerSwiper()
        }, 100);
        return;
      }
      new Swiper('.banner-box', {
        speed: 600,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        loop: true,
      });
    },
    initSponsorSwiper(){
      const tEl = document.querySelector(".sroom-slider")
      if (!tEl) {
        setTimeout(() => {
          this.initSponsorSwiper()
        }, 100);
        return;
      }
      new Swiper('.sroom-slider', {
        loop: true,
        autoplay: {
          disableOnInteraction: false
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        loopFillGroupWithBlank:true,
        // // 断点
        breakpoints:{
          200:{
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween:'20',
          },
          1000:{
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween:'40',
          },
        },
        pagination: {
          el: '.sroom-swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      });
    },
    initExpertsByGroupId(){
      getExpertsByGroupId({ groupId: '1388343158202368'}).then((res) => {
        if (res.success) {
          const list = res.data
          list.sort(function(a, b) {
              var wordsA = a.realname.trim().split(" "); // 将a的realname属性按空格分割成单词数组
              var wordsB = b.realname.trim().split(" "); // 将b的realname属性按空格分割成单词数组
              var lastWordA = wordsA[wordsA.length - 1].toLowerCase(); // 获取a的最后一个单词，并转换为小写
              var lastWordB = wordsB[wordsB.length - 1].toLowerCase(); // 获取b的最后一个单词，并转换为小写
              return lastWordA.localeCompare(lastWordB); // 使用localeCompare方法比较字符串，返回排序结果
          });
          //list.sort((x, y) => x.realname.localeCompare(y.realname))
          // const num = 4 - list.length%4
          // for (let index = 0; index < num; index++) {
          //   list.push({})
          // }
          this.speakerList = list
          this.$nextTick(()=>{
            this.initSwiper()
          })
        }
      })
    },
    initSponsors() {
      getSponsorList().then((res) => {
        if (res.success) {
          this.sponsors = res.data.showRoomAdvList || []
          this.$nextTick(()=>{
            this.initSponsorSwiper()
          })
        }
      })
    },
    async getWeatherInfo(){
      const res = await getWeatherInfo()
      console.log(res);
      if ( res.code === 0 ) {
        this.temp = ( res.data.main.temp - 273.15 )?.toFixed(0) || 25
        const weathinfo = res.data.weather[0]
        if ( weathinfo ) {
          this.weather = weathinfo.main
          if ( weathinfo.id < 300 ) {
            this.weatherIcon = require('@/assets/img/weather/11d@2x.png')
          } else if ( weathinfo.id < 500 ) {
            this.weatherIcon = require('@/assets/img/weather/09d@2x.png')
          } else if ( weathinfo.id < 600 ) {
            this.weatherIcon = require('@/assets/img/weather/10d@2x.png')
          } else if ( weathinfo.id < 700 ) {
            this.weatherIcon = require('@/assets/img/weather/13d@2x.png')
          } else if ( weathinfo.id < 800 ) {
            this.weatherIcon = require('@/assets/img/weather/50d@2x.png')
          } else if ( weathinfo.id === 800 ) {
            this.weatherIcon = require('@/assets/img/weather/01d@2x.png')
          } else if ( weathinfo.id === 801 ) {
            this.weatherIcon = require('@/assets/img/weather/02d@2x.png')
          } else if ( weathinfo.id === 802 ) {
            this.weatherIcon = require('@/assets/img/weather/03d@2x.png')
          } else if ( weathinfo.id === 803 ) {
            this.weatherIcon = require('@/assets/img/weather/04d@2x.png')
          }
        }
      }
    },
    palyer () {
      VideoPopup.install({videoUrl:'https://video.drvoice.cn/2f0fe650463b71eead214531948c0102/5a6343fb6ff94105abe4b7a341ddf2af-1b070786db4f44d6ddb708218de7092c-sd.m3u8', cover: require('@/assets/img/home/wuhan.jpg')})
    },
    toBeUpdate(){
      this.$router.push({name:'tbd'})
    },
    downloadFile(url){
      window.location.href = url
    },
    windowOpen(url){
      window.open(url)
    }
  }
};
</script>

<style lang="less" scoped>
.banner {
  background: linear-gradient(180deg, #2f4ba4 0%, #182848 100%);
  .centre {
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    .banner-carousel {
      // flex: 0 0 66.6%;
      width: 66.5%;
      display: inline-block;
      text-align: center;
      overflow: hidden;
      padding: 0 5px;
      .banner-carousel-item {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        img {
          max-height: 440px;
        }
        @media (max-width: 576px) {

        }
      }
    }
    .card-list {
      // flex: 0 0 33.3%;
      width: 33.3%;
      display: inline-block;
      padding: 0 5px ;
      ul {
        display: flex;
        flex-wrap: wrap;
        .card-item {
          flex: 0 0 50%;
          padding: 0 5px 10px;
          .card-box {
            position: relative;
            min-height: 140px;
            border: 1px solid transparent;
            background-color: #1a338d;
            padding: 10px 20px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              background-color: #244ba7;
              border: 1px solid #fff;
            }
            .card-title {
              font-size: 20px;
              font-weight: bold;
              color: #F8B301;
            }
            .card-icon {
              position: absolute;
              bottom: 10px;
              right: 10px;
              width: 50px;
            }
          }
        }
      }
    }
    @media (max-width:992px) {
      display: block;
      .banner-carousel {
        display: block;
        width: 100%;
        .banner-carousel-item{
          img {
            max-height: 483px;
          }
        }
      }
      .card-list {
        display: block;
        width: 100%;
      }
    }
  }
}
.container-box {
  padding: 40px 0;
  .container {
    max-width: 1320px;
    margin: 0 auto;
    .container-first {
      display: flex;
      margin: 0 -24px 40px;
      .icon-box {
        padding: 30px;
        position: relative;
        overflow: hidden;
        background-color: #f5f7fa;
        transition: all 0.3s ease-in-out;
        width: 100%;
        z-index: 1;
        margin: 0 24px;
        cursor: pointer;
        a {
          display: flex;
          width: 100%;
          height: 100%;
          .icon {
            font-size: 48px;
            line-height: 1em;
            vertical-align: top;
            color: #1a338d;
          }
          .box-text {
            padding-left: 15px;
            .title {
              font-weight: 700;
              font-size: 24px;
              color: #111;
            }
            .description {
              font-family: "PublicSans Regular";
              font-size: 18px;
              line-height: 28px;
              margin-bottom: 0;
              color: #F8B301;
            }
          }
        }
        &::before {
          content: "";
          position: absolute;
          background: #cbe0fb;
          right: 0;
          left: 0;
          bottom: 0;
          top: 100%;
          transition: all 0.3s;
          z-index: -1;
        }
        &:hover::before {
          background: #19317e;
          top: 0;
          border-radius: 0px;
        }
        &:hover {
          .icon {
            color: #fff;
          }
          a {
            color: #fff;
            .title {
              color: #fff;
            }
            .description {
              color: #fff;
            }
          }
        }
      }
    }
    @media (max-width:992px) {
      .container-first {
        display: block;
        margin: 0 0 15px;
        padding: 15px 0;
        .icon-box {
          margin-top: 15px;
          padding: 15px 30px;
          width: auto;
        }
      }
    }
    .container-top {
      background-color: #f5f7fa;
      padding: 15px 30px;
      min-height: 400px;
      margin-bottom: 40px;
      .main-box {
        padding: 20px 30px;
        position: relative;
        /deep/.speakers-slider  {
          position: relative;
          overflow: hidden;
          .swiper-slide {
            .speakers-card {
              background-color: #fff;
              padding: 30px 0 10px;
              background-image: url('../../assets/img/home/avatar-bg.jpg');
              background-size: contain;
              background-repeat: no-repeat;
              max-width: 230px;
              margin: 0 auto;
              .img {
                border-radius: 50%;
                width: 110px;
                margin: 0 auto 10px;
              }
              h3 {
                height: 72px;
                font-size: 24px;
                color: #000;
                margin: 0;
                text-align: center;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              p {
                font-family: "PublicSans Regular";
                font-size: 16px;
                margin: 0;
                text-align: center;
                border-bottom: 1px solid #F2F4F9;
                margin-bottom: 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 72px;
                display: flex;
                flex: 1;
                align-items: start;
                justify-content: center;
              }
              .btns {
                display: flex;
                justify-content: space-around;
                height: 26px;
                .btn {
                  padding: 0px 5px;
                  font-size: 16px;
                  font-weight: 600;
                  color: #122359;
                  border: 1px solid #122359;
                  border-radius: 4px;
                  cursor: pointer;
                }
              }
            }
          }
          .swiper-pagination {
            margin-top: 20px;
            position: relative;
            .swiper-pagination-bullet {
              width: 12px;
              height: 12px;
              transition: all 0.2s;
              background: #c4c6c8;
              opacity: 1;
              &.swiper-pagination-bullet-active {
                background-color: #FEE363;
                // transform: scale(1.2);
              }
            }
          }
        }
        .swiper-button-prev:after, .swiper-button-next:after {
          color: #1a338d;
          font-weight: bold;
        }
        .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
          left: 0;
        }
        .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
          right: 0;
        }

      }
    }
    .container-mid {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .left {
        flex: 0 0 32%;
        background-color: #f5f7fa;
        padding: 15px 30px;
        .main-box {
          padding-top: 20px;
          .img {
            img {
              width: 100%;
            }
          }
          p {
            // margin: 1em 0;
            color: #383333;
            font-family: "PublicSans Regular";
            font-size: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            text-align: justify;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .main-bottom {
            height: 36px;
            .more {
              float: right;
              font-size: 20px;
              font-weight: 800;
              color: #19317E;
              line-height: 36px;
              .iconfont {
                font-size: 20px;
              }
            }
          }
        }
      }
      .centre {
        flex: 0 0 32%;
        background-color: #f5f7fa;
        padding: 15px 30px;
        .main-box {
          padding-top: 20px;
          .img {
            img {
              width: 100%;
            }
          }
          h4 {
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            margin: 0;
            height: 40px;
            margin-top: 10px;
          }
          .main-bottom {
            height: 36px;
            display: flex;
            .num {
              height: 36px;
              width: 36px;
              background: #fff;
              color: #19317E;
              font-size: 20px;
              line-height: 36px;
              text-align: center;
              margin-right: 6px;
              cursor: pointer;
            }
            .more {
              float: right;
              font-size: 20px;
              font-weight: 800;
              color: #19317E;
              line-height: 36px;
              .iconfont {
                font-size: 20px;
              }
            }
          }
        }
      }
      .right {
        flex: 0 0 32%;
        div {
          height: 79px;
          margin-bottom: 1px;
          padding-left: 30px;
          color: #fff;
          font-size: 28px;
          font-weight: 800;
          color: #FFFFFF;
          line-height: 79px;
          transition: all .3s;
          &:last-child {
            margin: 0;
          }
          &:not(:first-child):hover {
            cursor: pointer;
            background-color: #f8b301;
          }
          img {
            display: inline-block;
            line-height: 79px;
            vertical-align: middle;
            height: 20px;
            margin-right: 10px;
          }
        }
        .bo1 {
          color: #f8b301;
          background-color: #19317e;
          padding: 0 30px;
          .iconfont {
            float: right;
            font-size: 40px;
          }
        }
        .bo2 {
          background-color: #1d3993;
        }
        .bo3 {
          background-color: #2446b3;
        }
        .bo4 {
          background-color: #2a53d5;
        }
        .bo5 {
          background-color: #3363ff;
        }
      }
      @media (max-width:992px) {
        display: block;
        .left ,.centre{
          margin-bottom: 40px;
        }
      }
    }
    .container-bottom {
      background-color: #f5f7fb;
      margin-bottom: 40px;
      padding: 15px 30px;
      .sroom-slider {
        margin-top: 20px;
        position: relative;
        overflow: hidden;
        .sroom-box {
          background-color: #fff;
          img {
            max-width: 100%;
            min-height: 60px;
          }
        }
        /deep/ .sroom-swiper-pagination {
            margin-top: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            .swiper-pagination-bullet {
              width: 12px;
              height: 12px;
              transition: all 0.2s;
              background: #c4c6c8;
              &.swiper-pagination-bullet-active {
                background-color: #FEE363;
                transform: scale(1.2);
              }
            }
          }
      }
    }
    .container-last {
      display: flex;
      .left {
        flex: 1;
        padding-right: 15px;
        .bgbox {
          padding: 0 30px 30px;
          height: 100%;
          background-color: #f5f7fb;
          .img {
            position: relative;
            img {
              width: 100%;
            }
            .player {
              width: 18%;
              max-width: 100px;
              position: absolute;
              cursor: pointer;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      .right {
        flex: 1;
        padding-left: 15px;
        .bgbox {
          padding: 0 30px 30px;
          height: 100%;
          background-color: #f5f7fb;
          .img {
            position: relative;
            .bg {
              width: 100%;
            }
            .box {
              position: absolute;
              width: 100%;
              left: 0;
              top: 0;
              display: flex;
              color: #fff;
              padding: 18px 0 0 20px;
              font-family: "Akrobat-Regular";
              .box-lt {
                font-size: 90px;
                line-height: 1em;
              }
              .box-mid {
                flex: 1;
                font-size: 30px;
                padding-left: 1em;
              }
              .box-rt img {
                height: 120px;
                max-height: 120px;
              }
              @media (max-width:575px) {
                .box-lt {
                  font-size: 60px;
                  line-height: 1em;
                }
                .box-mid {
                  flex: 1;
                  font-size: 20px;
                }
                .box-rt img {
                  max-height: 90px;
                }
              }
            }
          }
        }
      }
      @media (max-width:992px) {
        display: block;
        .left {
          padding-right: 0;
          margin-bottom: 40px;
        }
        .right {
          padding: 0;
        }
      }
    }
    .main-title { // 公共标题
      color: #222222;
      font-size: 28px;
      font-weight: 800;
      line-height: 60px;
      border-bottom: 1px solid #DEE2E6;
      overflow: hidden;
      span {
        white-space: normal;
      }
      .more {
        float: right;
        font-size: 20px;
        font-weight: 800;
        color: #19317E;
        line-height: 60px;
        cursor: pointer;
        .iconfont {
          font-size: 20px;
        }
      }
      @media (max-width:576px) {
        span {
          white-space: normal;
          line-height: 1em;
          font-size: 22px;
        }
        .more {
          line-height: 20px;
          padding-bottom: 10px;
        }
      }

    }
    @media (max-width:576px) {

      .container-top,.container-bottom {
        padding: 5px 15px 15px;
        margin-bottom: 15px;
      }
      .container-mid {
        margin-bottom: 15px;
        .left ,.centre {
          padding: 5px 15px;
          margin-bottom: 15px;
        }
        .right div {
          display: flex;
          align-items: center;
          line-height: 30px;
          font-size: 26px;
          padding-left: 15px;
          &.bo1 .iconfont {
            font-size: 32px;
          }
        }
      }
      .container-last .left {
        margin-bottom: 15px;
      }
      .container-last .left .bgbox,
      .container-last .right .bgbox {
        padding: 0 15px 15px;
      }
    }
  }
  @media (max-width:576px) {
    padding: 0;
  }

}
</style>
