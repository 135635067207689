<template>
  <div class="video-popup-wrap" ref="videoPopupWrap" v-show="show">
    <div class="mask" @click="popupDestroy"></div>
    <div class="popup-container" :style="containerStyle">
      <div class="popup-content" @click.stop="videoClickHandle">
        <VueAliplayerV2
          ref="videoPopupPlayer"
          v-if="playVisible"
          :options="options"
          @ready="handleReady"
          @showBar="handleShowBar"
        ></VueAliplayerV2>
      </div>
    </div>
    <div class="fake" :style="containerStyle">
      <div class="close" @click="popupDestroy" :style="isMobile?'right:0;top:-30px;':''"><a-icon type="close-circle" theme="filled" /></div>
    </div>
  </div>
</template>

<script>
  import VueAliplayerV2 from 'vue-aliplayer-v2'
  export default {
    name: 'VideoPopup',
    components: {
      VueAliplayerV2
    },
    data() {
      return {
        show: false,
        playVisible: false,
        videoUrl: '',
        cover: '',
        containerStyle: {
          height: '400px',
          width: '710px'
        },
        options: {
          config: {
            autoplay: true,
            cover: '', // 默认图片
            width: '100%',
            height: '400px',
            isLive: false,
            diagnosisButtonVisible: false,
            liveRetry: 2,
            skinLayout: [
              { name: 'bigPlayButton', align: 'blabs', x: 30, y: 80 },
              { name: 'H5Loading', align: 'cc' },
              { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
              { name: 'thumbnail' },
              {
                name: 'controlBar',
                align: 'blabs',
                x: 0,
                y: 0,
                children: [
                  { name: 'progress', align: 'blabs', x: 0, y: 44 },
                  { name: 'playButton', align: 'tl', x: 15, y: 12 },
                  { name: 'timeDisplay', align: 'tl', x: 10, y: 5 },
                  { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
                  { name: 'setting', align: 'tr', x: 15, y: 12 },
                  { name: 'snapshot', align: 'tr', x: 5, y: 10 },
                  { name: 'volume', align: 'tr', x: 5, y: 10 }
                ]
              },
            ],
            controlBarVisibility: 'hover',
            showBarTime: '3000'
          },
          source: '',
          forbidFastForward: false
        },
      }
    },
    created() {
      this.handleShowBar = this.throttle(this.handleShowBar)
    },
    methods: {
      showPopup() {
        this.options.source = this.videoUrl
        if (this.isMobile) {
          const width = document.body.clientWidth - 10
          const height = Math.floor(width / 2.22)
          this.containerStyle = {
            height: `${height}px`,
            width: `${width}px`
          }
          this.options.config.height = `${height}px`
          this.options.config.width = `${width}px`
        } else {
          const width = document.body.clientWidth* 0.8
          const height = Math.floor(width / 2.22)
          this.containerStyle = {
            height: `${height}px`,
            width: `${width}px`
          }
          this.options.config.height = `${height}px`
          this.options.config.width = `${width}px`
        }
        this.playVisible = true
        this.$nextTick(() => {
          console.log('show')
          this.show = true
        })
      },
      popupDestroy() {
        this.show = false
        this.$nextTick(() => {
          document.body.removeChild(this.$refs.videoPopupWrap)
        })
      },
      handleReady() {
        this.$nextTick(() => {
          this.$refs.videoPopupPlayer && this.$refs.videoPopupPlayer.play()
        })
      },
      handleShowBar() {
        if (this.isMobile) {
          setTimeout(() => {
            const controlbarEl = document.getElementsByClassName('prism-controlbar')[0]
            if (controlbarEl) {
              controlbarEl.style = controlbarEl.getAttribute('style').replace('display: block', 'display: none')
            }
          }, 5000);
        }
      },
      videoClickHandle() {
        console.log('videoClickHandle')
      },
      throttle(fn, wait = 1000) {
        let timer = null
        return function() {
          let context = this
          let args = arguments
          if (!timer) {
            timer = setTimeout(function() {
              fn.apply(context, args)
              timer = null
            }, wait)
          }
        }
      }
    },
    computed: {
      isMobile() {
        return document.documentElement.clientWidth < 575
      }
    }
  }
</script>


<style lang="less" scoped>
  // 渐变过渡
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .35s;
  }
  .video-popup-wrap {
    overflow: hidden;
  }

  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 98;
  }

  .popup-container {
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    animation: changeScale 0.1s linear;
    .close {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 20px;
      height: 20px;
      color: #fff;
      font-size: 20px;
      line-height: 20px;
      z-index: 100;
    }
  }
  .fake {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    .close {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 20px;
      height: 20px;
      color: #fff;
      font-size: 20px;
      line-height: 20px;
      z-index: 100;
    }
  }

  .popup-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-size: 34px;
    color: #333;
    // background-color: #000;
    line-height: 1.5;
    .popup-txt {}
    .popup-desc {
      font-size: 28px;
    }
    .popup-desc {
      white-space: pre;
    }
    /deep/ .prism-player {
      width: 100% !important;
      height: 100% !important;
      video {
        &:focus {
          outline: none !important;
        }
        outline: none !important;
        object-fit: cover;
      }
      .prism-big-play-btn {
        left: calc(50% - 32px)!important;
        bottom: calc(50% - 32px)!important;
      }
    }
  }

  .popup-footer {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .commBtn {
      width: 50%;
      height: 93px;
      display: flex;
      align-items: center;
      justify-content: center;
     /* background-color: $main;
      border-top: 1px solid $main;*/
      font-size: 30px;
      color: #fff;
    }
    .cancle {
      background-color: #fff;
      color: #999;
      border-top-color: #ebebeb;
      border-radius: 0 0 0 18px;
    }
    .confirm {
      border-radius: 0 0 18px 0;
    }
    .noTrue {
      width: 100%;
      /*background-color: $main;*/
      border-radius: 0 0 18px 18px;
    }
  }

  @keyframes changeScale {
    0% {
      transform: translate(-50%, -50%) scale(1.1, 1.1);
    }
    100% {
      transform: translate(-50%, -50%) scale(1, 1);
    }
  }

</style>