import request from '@/utils/request'

const weatherInfoApi = {
    getWeatherInfoUrl: '/api/sysMeeting/weather',
}

/**
 * 獲取天氣信息
 * @param params
 * @returns {*}
 */
export function getWeatherInfo(params) {
    return request({
        url: weatherInfoApi.getWeatherInfoUrl,
        method: 'get',
        params
    })
}
